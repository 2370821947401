import React, { useEffect, useMemo } from 'react';
import useAlertStore from '../../stores/alertStore';
import { AlertContainer, Message, ActionButton, Title } from './alert.styles';
import MaterialIcon from '../material-icon';
import { colorPalette } from '../../utils/theme';
import CTAButton from '../cta-button/cta-button';
import useIsMobile from '../../hooks/useIsMobile';

const Alert: React.FC = () => {
  // Stores
  const isMobile = useIsMobile();
  const { type, title, message, actionLabel, onActionClick, visible, hideAlert } = useAlertStore();

  // Auto-hide the alert after 4000ms
  useEffect(() => {
    if (visible) {
      const timer = setTimeout(() => {
        hideAlert();
      }, 4000);

      return () => clearTimeout(timer);
    }
  }, [visible, hideAlert]);

  // Functions
  const statusColor = useMemo(() => {
    if (type === "success") return colorPalette.successDark;
    else if (type === "warning") return colorPalette.warningDark;
    else if (type === "error") return colorPalette.errorDark;
    else if (type === "info") return colorPalette.textMain;
    else if (type === "action") return colorPalette.white;
    return colorPalette.blue500;
  }, [type]);

  const statusIcon = useMemo(() => {
    if (type === "success") return "check";
    else if (type === "warning") return "warning";
    else if (type === "error") return "emergency_home";
    else if (type === "info") return "info";
    else if (type === "action") return "photo";
    return "check";
  }, [type]);

  if (!visible) return null;

  return (
    <AlertContainer type={type} role="alert">
      <div className="d-flex align-items-center justify-content-center" style={{ gap: 16 }}>
        <MaterialIcon icon={statusIcon} size={24} color={statusColor} />
        <div>
          <Title>{title}</Title>
          {message && message.length > 0 && (
            <Message>{message}</Message>
          )}
        </div>
      </div>
      {actionLabel && onActionClick && (
        <CTAButton
          variant="ghost"
          size="M"
          icon="arrow_forward"
          label={isMobile ? "" : actionLabel}
          color={statusColor}
          onClick={onActionClick}
          noPadding
        />
      )}
    </AlertContainer>
  );
};

export default Alert;
