import './fonts.css';
import './index.css';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.bundle';
import 'react-phone-number-input/style.css'
import '@material-symbols/font-400/outlined.css';

import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import { Navigate, RouterProvider, createBrowserRouter } from 'react-router-dom';

// Routes
import Root from './routes/root';
import ProtectedRoute from './routes/protected-route';
import PageContainerWrapper from './routes/page-container-wrapper';
import * as Sentry from "@sentry/react";
import FAQPage from './routes/knowledge-hub/sub-pages/faq-page/faq-page';
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import { PWAProvider, usePWA } from './context/PWAContext';
import useIsMobile from './hooks/useIsMobile';
import useInvestorDetailStore from './stores/investorDetailStore';

// Pages
const ErrorPage = React.lazy(() => import('./routes/error-page/error-page'));
const LoginPage = React.lazy(() => import('./routes/login-page/login-page'));
const ResetPasswordPage = React.lazy(() => import('./routes/reset-password-page/reset-password-page'));
const CoursePage = React.lazy(() => import('./routes/course-page'));
const MyUnitsPage = React.lazy(() => import('./routes/my-units-page/my-units-page'));
const DashboardPage = React.lazy(() => import('./routes/dashboard-page/dashboard-page'));
const ProfilePage = React.lazy(() => import('./routes/profile-page/profile-page'));
const CourseDetailPage = React.lazy(() => import('./routes/course-detail-page/course-detail-page'));
const UnitDetailPage = React.lazy(() => import('./routes/unit-detail-page/unit-detail-page'));
const PackageSelectionPage = React.lazy(() => import('./routes/package-selection-page/package-selection-page'));
const FinancialsPage = React.lazy(() => import('./routes/financials-page/financials-page'));
const ProjectPage = React.lazy(() => import('./routes/project-page/project-page'));
const ContractsPage = React.lazy(() => import('./routes/contracts-page/contracts-page'));
const KnowledgeHubPage = React.lazy(() => import('./routes/knowledge-hub/knowledge-hub'));
const ProfileBankDetailsPage = React.lazy(() => import('./routes/profile-page/sub-pages/bank-details-page/bank-details-page'));
const ProfileAddresDetailsPage = React.lazy(() => import('./routes/profile-page/sub-pages/address-details-page/address-details-page'));
const ProfileSettingsPage = React.lazy(() => import('./routes/profile-page/sub-pages/settings-page/settings-page'));
const ProfileEditAddresDetailsPage = React.lazy(() => import('./routes/profile-page/sub-pages/edit-address-details-page/edit-address-details-page'));
const ProfileEditBankDetailsPage = React.lazy(() => import('./routes/profile-page/sub-pages/edit-bank-details-page/edit-bank-details-page'));
const ProfileDocumentDetailsPage = React.lazy(() => import('./routes/profile-page/sub-pages/document-details-page/document-details-page'));
const PrivacyPolicyPage = React.lazy(() => import('./routes/privacy-policy-page/privacy-policy-page'));
const TermsAndConditionsPage = React.lazy(() => import('./routes/terms-and-conditions-page/terms-and-conditions-page'));
const KnowledgeHubArticleDetailPage = React.lazy(() => import('./routes/knowledge-hub/sub-pages/article-detail-page/article-detail-page'));
const OnboardingPage = React.lazy(() => import('./routes/onboarding-page/onboarding-page'));
const HomeLandingPage = React.lazy(() => import('./routes/landing-page/sub-pages/home-page/home-page'));
const DetailsLandingPage = React.lazy(() => import('./routes/landing-page/sub-pages/details-page/details-page'));
const LandingPageArticleDetailPage = React.lazy(() => import('./routes/landing-page/sub-pages/article-detail-page/article-detail-page'));

const isProduction = process.env.REACT_APP_ENV === "production";

Sentry.init({
  dsn: "https://352009d95c7053d2edcab1a5b0b50a3b@o4507089669849088.ingest.de.sentry.io/4507089726603344",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  tracesSampleRate: isProduction ? 0.1 : 1.0,
  tracePropagationTargets: [
    "localhost",
    /^https:\/\/api\.getmanagd\.com/,
    /^https:\/\/api\.managd\.ae/
  ],
  // Session Replay
  replaysSessionSampleRate: isProduction ? 0.2 : 1.0,
  replaysOnErrorSampleRate: 1.0,
});

const RedirectToHome: React.FC = () => {
  const isMobile = useIsMobile();

  const checkIfPWA = window.matchMedia('(display-mode: standalone)').matches;

  // Stores
  const investor = useInvestorDetailStore(state => state.investor);

  if (isMobile && checkIfPWA && investor) {
    return <Navigate to="/dashboard" replace />;
  }

  return <Navigate to="/home" replace />;
};

const router = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "/",
        element: <RedirectToHome />
      },
      {
        path: "/dashboard",
        element: <Suspense fallback={(
          <div className="d-flex justify-content-center align-items-center" style={{ minHeight: '100vh' }}>
            <div className="spinner-border text-primary" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        )}><ProtectedRoute>
            <PageContainerWrapper id="dashboard">
              <DashboardPage />
            </PageContainerWrapper>
          </ProtectedRoute>
        </Suspense>,
      },
      {
        path: "/home",
        element: <HomeLandingPage />,
      },
      {
        path: "/details",
        element: <DetailsLandingPage />,
      },
      {
        path: "/articles/:id",
        element: <LandingPageArticleDetailPage />,
      },
      {
        path: "/onboarding",
        element: <ProtectedRoute>
          <PageContainerWrapper id="onboarding">
            <OnboardingPage />
          </PageContainerWrapper>
        </ProtectedRoute>,
      },
      {
        path: "/profile",
        element: <ProtectedRoute>
          <PageContainerWrapper id="my-profile">
            <ProfilePage />
          </PageContainerWrapper>
        </ProtectedRoute>,
      },
      {
        path: "/profile/bank-details",
        element: <ProtectedRoute>
          <PageContainerWrapper id="my-profile-bank-details">
            <ProfileBankDetailsPage />
          </PageContainerWrapper>
        </ProtectedRoute>,
      },
      {
        path: "/profile/edit-bank-details",
        element: <ProtectedRoute>
          <PageContainerWrapper id="my-profile-edit-bank-details">
            <ProfileEditBankDetailsPage />
          </PageContainerWrapper>
        </ProtectedRoute>,
      },
      {
        path: "/profile/address-details",
        element: <ProtectedRoute>
          <PageContainerWrapper id="my-profile-address-details">
            <ProfileAddresDetailsPage />
          </PageContainerWrapper>
        </ProtectedRoute>,
      },
      {
        path: "/profile/documents",
        element: <ProtectedRoute>
          <PageContainerWrapper id="my-profile-documents">
            <ProfileDocumentDetailsPage />
          </PageContainerWrapper>
        </ProtectedRoute>,
      },
      {
        path: "/profile/edit-address",
        element: <ProtectedRoute>
          <PageContainerWrapper id="my-profile-edit-address">
            <ProfileEditAddresDetailsPage />
          </PageContainerWrapper>
        </ProtectedRoute>,
      },
      {
        path: "/profile/settings",
        element: <ProtectedRoute>
          <PageContainerWrapper id="my-profile-settings">
            <ProfileSettingsPage />
          </PageContainerWrapper>
        </ProtectedRoute>,
      },
      {
        path: "/my-units",
        element: <ProtectedRoute>
          <PageContainerWrapper id="my-units">
            <MyUnitsPage />
          </PageContainerWrapper>
        </ProtectedRoute>,
      },
      {
        path: "/my-units/:unitId",
        element: <ProtectedRoute>
          <PageContainerWrapper id="my-units">
            <UnitDetailPage />
          </PageContainerWrapper>
        </ProtectedRoute>,
      },
      {
        path: "/course",
        element: <ProtectedRoute>
          <PageContainerWrapper id="course">
            <CoursePage />
          </PageContainerWrapper>
        </ProtectedRoute>,
      },
      {
        path: "/course/:itemId",
        element: <ProtectedRoute>
          <PageContainerWrapper id="course-detail">
            <CourseDetailPage />
          </PageContainerWrapper>
        </ProtectedRoute>,
      },
      {
        path: "/knowledge-hub",
        element: <ProtectedRoute>
          <PageContainerWrapper id="knowledge-hub">
            <KnowledgeHubPage />
          </PageContainerWrapper>
        </ProtectedRoute>,
      },
      {
        path: "/knowledge-hub/articles/:id",
        element: <ProtectedRoute>
          <PageContainerWrapper id="knowledge-hub-article">
            <KnowledgeHubArticleDetailPage />
          </PageContainerWrapper>
        </ProtectedRoute>,
      },
      {
        path: "/faq",
        element: <ProtectedRoute>
          <PageContainerWrapper id="faq">
            <FAQPage />
          </PageContainerWrapper>
        </ProtectedRoute>,
      },
      {
        path: "/packages",
        element: <ProtectedRoute>
          <PageContainerWrapper id="package-selection">
            <PackageSelectionPage />
          </PageContainerWrapper>
        </ProtectedRoute>,
      },
      {
        path: "/financials",
        element: <ProtectedRoute>
          <PageContainerWrapper id="financials">
            <FinancialsPage />
          </PageContainerWrapper>
        </ProtectedRoute>,
      },
      {
        path: "/contracts",
        element: <ProtectedRoute>
          <PageContainerWrapper id="contracts">
            <ContractsPage />
          </PageContainerWrapper>
        </ProtectedRoute>,
      },
      {
        path: "/project/:propertyId",
        element: <ProtectedRoute>
          <PageContainerWrapper id="project">
            <ProjectPage />
          </PageContainerWrapper>
        </ProtectedRoute>,
      },
      {
        path: "/login",
        element: <LoginPage />,
      },
      {
        path: "/reset-password",
        element: <PageContainerWrapper id="reset-password" fullPage>
          <ResetPasswordPage />
        </PageContainerWrapper>,
      },
      {
        path: "/privacy-policy",
        element: <PrivacyPolicyPage />,
      },
      {
        path: "/terms-and-conditions",
        element: <TermsAndConditionsPage />,
      },
    ],
  },
]);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
    <PWAProvider>
      <RouterProvider router={router} />
    </PWAProvider>
  </React.StrictMode>
);

serviceWorkerRegistration.register();

reportWebVitals();

const setFavicon = () => {
  const env = process.env.REACT_APP_ENV; // Get environment
  const basePath = env === 'production' ? '/favicon/production/' : '/favicon/staging/';

  const icons = [
    { rel: 'icon', type: 'image/png', sizes: '32x32', href: `${basePath}favicon-32x32.png` },
    { rel: 'icon', type: 'image/png', sizes: '16x16', href: `${basePath}favicon-16x16.png` },
    { rel: 'apple-touch-icon', sizes: '180x180', href: `${basePath}apple-touch-icon.png` },
    { rel: 'icon', href: `${basePath}favicon.ico` }, // Default favicon
  ];

  icons.forEach(({ rel, type, sizes, href }) => {
    let link = document.querySelector(`link[rel='${rel}'][sizes='${sizes || ''}']`) as HTMLLinkElement | null;

    if (!link) {
      link = document.createElement('link') as HTMLLinkElement;
      link.rel = rel;
      if (type) link.setAttribute("type", type); // Use setAttribute
      if (sizes) link.setAttribute("sizes", sizes); // Use setAttribute
      document.head.appendChild(link);
    }
    link.href = href;
  });
};

// Call it at app startup
setFavicon();

const setManifest = () => {
  const env = process.env.REACT_APP_ENV;
  const basePath = env === 'production' ? '/favicon/production/' : '/favicon/staging/';

  const manifest = {
    name: "Managd - Employee Portal",
    short_name: "Managd",
    icons: [
      { src: `${basePath}android-chrome-192x192.png`, sizes: "192x192", type: "image/png" },
      { src: `${basePath}android-chrome-512x512.png`, sizes: "512x512", type: "image/png" }
    ],
    theme_color: "#ffffff",
    background_color: "#ffffff",
    display: "standalone"
  };

  const stringifiedManifest = JSON.stringify(manifest);
  const blob = new Blob([stringifiedManifest], { type: "application/json" });
  const manifestURL = URL.createObjectURL(blob);

  let link = document.querySelector("link[rel='manifest']") as HTMLLinkElement | null;
  if (!link) {
    link = document.createElement("link") as HTMLLinkElement;
    link.rel = "manifest";
    link.setAttribute("href", manifestURL);
    document.head.appendChild(link);
  } else {
    link.setAttribute("href", manifestURL);
  }
};

// Call it at app startup
setManifest();
