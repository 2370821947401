import React, { useEffect, useState } from 'react';
import {
    ModalBody,
    Header,
    HeaderTitle,
    ModalContainer,
    Overlay,
    ContentWrapper,
    Title,
    Description,
} from './contact-us-modal.styles';
import MaterialIcon from '../../components/material-icon';
import { colorPalette } from '../../utils/theme';
import useIsMobile from '../../hooks/useIsMobile';
import { Row, Col } from 'react-bootstrap';
import ContactCard from '../../components/contact-card/contact-card';
import { MobileContainer } from '../../routes/profile-page/components/contacts-list/contacts-list.styles';
import { Divider } from '../../routes/reset-password-page/components/six-digit-code.styles';
import useContactUsModalStore from '../../stores/contactUsModalStore';
import CTAButton from '../../components/cta-button/cta-button';
import useCustomNavigate from '../../hooks/useCustomNavigate';
import useInvestorDetailStore from '../../stores/investorDetailStore';

const ContactUsModal: React.FC = () => {
    const navigate = useCustomNavigate();
    const isMobile = useIsMobile();

    // Stores
    const { isVisible, setIsVisible } = useContactUsModalStore();
    const investor = useInvestorDetailStore(state => state.investor);

    // States
    const [isClosing, setIsClosing] = useState(false);

    // Functions
    const handleClose = () => {
        setIsClosing(true);
        setTimeout(() => {
            setIsVisible(false);
            setIsClosing(false);
        }, isMobile ? 300 : 0);
    };

    // Prevent scrolling down
    useEffect(() => {
        if (isVisible) {
            // Disable background scrolling
            document.body.style.overflow = 'hidden';

            // Prevent touchmove only if the event is outside the modal
            const preventBackgroundScroll = (e: TouchEvent) => {
                if (!(e.target as HTMLElement).closest('.sidebar-modal-content')) {
                    e.preventDefault();
                }
            };

            document.addEventListener('touchmove', preventBackgroundScroll, { passive: false });

            return () => {
                // Restore background scrolling
                document.body.style.overflow = 'unset';
                document.removeEventListener('touchmove', preventBackgroundScroll);
            };
        }
    }, [isVisible]);

    if (!isVisible && !isClosing) return null;

    return (
        <Overlay onClick={handleClose}>
            <ModalContainer onClick={(e) => e.stopPropagation()} isClosing={isClosing}>
                <Header>
                    <div>
                        <HeaderTitle>Hilfe & Support</HeaderTitle>
                    </div>
                    <MaterialIcon icon="close" size={24} color={colorPalette.blue500} onClick={handleClose} />
                </Header>
                <ModalBody className="sidebar-modal-content">
                    <ContentWrapper>
                        <Title>Kontaktiere Uns</Title>
                        <Description>
                            Unser Team hilft dir gerne bei Fragen zu unseren Paketen oder der Managd-Plattform.
                            {!isMobile && <br />}
                            Wähle einfach eine der folgenden Kommunikationsmöglichkeiten aus:
                        </Description>

                        {isMobile ? (
                            <MobileContainer>
                                <ContactCard type="whatsapp" />
                                <Divider />
                                <ContactCard type="phone" />
                                <Divider />
                                <ContactCard type="email" />
                            </MobileContainer>
                        ) : (
                            <Row className="gy-2">
                                <Col xs={12} xl={4}>
                                    <ContactCard type="whatsapp" />
                                </Col>
                                <Col xs={12} xl={4}>
                                    <ContactCard type="phone" />
                                </Col>
                                <Col xs={12} xl={4}>
                                    <ContactCard type="email" />
                                </Col>
                            </Row>
                        )}

                        {investor && (
                            <>
                                <Title style={{ marginTop: 32 }}>Häufige Fragen</Title>
                                <div className={`align-items-center ${isMobile ? "" : "d-flex"}`} style={{ gap: 4 }}>
                                    <Description style={{ margin: 0 }}>
                                        Antworten auf häufige Fragen findest du auch in unseren
                                    </Description>
                                    <CTAButton
                                        variant="ghost"
                                        size={isMobile ? "S" : "L"}
                                        label="FAQs"
                                        icon="arrow_outward"
                                        color={colorPalette.blue500}
                                        onClick={() => {
                                            setIsVisible(false);
                                            navigate("/faq");
                                        }}
                                        noPadding
                                    />
                                </div>
                            </>
                        )}
                    </ContentWrapper>
                </ModalBody>
            </ModalContainer>
        </Overlay>
    );
};

export default ContactUsModal;
