import React from "react";
import { CardWrapper, Contact, Icon, Time, Title } from "./contact-card.styles";
import { colorPalette } from "../../utils/theme";
import MaterialIcon from "../material-icon";
import WhatsappIcon from "../../assets/icons/whatsapp.svg";
import useIsMobile from "../../hooks/useIsMobile";

interface ContactCardProps {
    type: "phone" | "email" | "whatsapp";
}

const ContactCard: React.FC<ContactCardProps> = ({ type }) => {
    const isMobile = useIsMobile();

    const renderIcon = () => {
        switch (type) {
            case "phone":
                return <MaterialIcon icon="call" size={isMobile ? 24 : 32} color={colorPalette.primaryDark} />;
            case "email":
                return <MaterialIcon icon="mark_as_unread" size={isMobile ? 24 : 32} color={colorPalette.primaryDark} />;
            case "whatsapp":
                return <Icon src={WhatsappIcon} alt="WhatsApp Icon" />;
            default:
                return null;
        }
    };

    const renderTitle = () => {
        switch (type) {
            case "phone":
                return "Ruf uns an";
            case "email":
                return "Schreib uns";
            case "whatsapp":
                return "WhatsApp";
            default:
                return null;
        }
    };

    const renderContact = () => {
        switch (type) {
            case "phone":
                return "+971 58 108 5434";
            case "email":
                return "support@getmanagd.com";
            case "whatsapp":
                return "+971 58 108 5434";
            default:
                return null;
        }
    };

    const renderTime = () => {
        switch (type) {
            case "phone":
                return "Mo.-Fr. 08.00 - 17.00 (GST)";
            case "email":
                return null;
            case "whatsapp":
                return "Mo.-Fr. 08.00 - 17.00 (GST)";
            default:
                return null;
        }
    };

    return (
        <CardWrapper>
            <div className={isMobile ? "d-flex align-items-center" : ""} style={isMobile ? { gap: 10 } : {}}>
                {renderIcon()}
                <Title>{renderTitle()}</Title>
            </div>
            <div className={isMobile ? "text-end" : ""}>
                <Contact
                    href={
                        type === "email"
                            ? `mailto:${renderContact()}`
                            : type === "whatsapp"
                                ? `https://wa.me/${renderContact()?.replace(/\D/g, "")}`
                                : `tel:${renderContact()}`
                    }
                    target="__blank"
                >
                    {renderContact()}
                </Contact>
                {type !== "email" && (
                    <Time>{renderTime()}</Time>
                )}
            </div>
        </CardWrapper>
    );
};

export default ContactCard;
