// faq-categories.styles.ts (styles file)
import styled from "styled-components";
import { colorPalette, typeScale } from "../../../../utils/theme";

export const Container = styled.section`
  width: 100%;
  padding: 16px;

  @media (max-width: 767.98px) {
    padding: 24px 16px 24px 16px;
    border-top: 1px solid ${colorPalette.blue200};
    border-bottom: 1px solid ${colorPalette.blue200};
  }
`;

export const Header = styled.div`
  margin-bottom: 16px;
`;

export const HeaderTitle = styled.h2`
  margin-bottom: 4px;
  color: ${colorPalette.textMain};
  ${typeScale.headingMain};

  @media (max-width: 767.98px) {
    ${typeScale.headingSMMain};
  }
`;

export const HeaderSubtitle = styled.p`
  margin-bottom: 0;
  color: ${colorPalette.textDark};
  ${typeScale.bodyMD};

  @media (max-width: 767.98px) {
    ${typeScale.bodySM};
  }
`;

export const FAQItem = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 0.5em;
  background-color: ${colorPalette.blue50};
  border: 1px solid ${colorPalette.blue200};
  padding: 16px;
  display: flex;
  flex-direction: column;
  transition: transform 0.3s ease;

  &:hover {
    transform: scale(0.98);
    cursor: pointer;
  }
`;

export const TopSection = styled.div`
  min-height: 80px;
  flex: 1;
  display: flex;
  justify-content: flex-start;
`;

export const IconContainer = styled.div`
  height: 36px;
  width: 36px;
`;

export const BottomSection = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`;

export const Title = styled.div`
  color: ${colorPalette.textMain};
  ${typeScale.bodyLGMain};
  margin-bottom: 4px;
`;

export const Description = styled.p`
  margin: 0;
  color: ${colorPalette.textDark};
  ${typeScale.bodySM};
  overflow: hidden;

  @media (max-width: 767.98px) {
    ${typeScale.bodyXS};
  }
`;
